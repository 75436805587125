import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"
import escape from "lodash/escape"
import isEmpty from "lodash/isEmpty"

export default class extends Controller {
  static targets = ["input", "select", "suggestions", "suggestionHeader", "hiddenInput"]
  static values = {
    url: { type: String, default: "/search" },
    mode: { type: String, default: "search" } // Can be "search" or "clientSelect"
  }

  connect() {
    this.query = null
    this.clickedSuggestion = false
    this.debouncedQuery = debounce(this.runQuery.bind(this), 280)
  }

  // Handle input changes
  search() {
    this.query = this.inputTarget.value
    const category = this.hasSelectTarget ? this.selectTarget.value : null
    
    // Skip search for Users and Companies categories
    if (category === "Users" || category === "Companies") {
      this.suggestionsTarget.style.display = "none"
      return
    }
    
    this.debouncedQuery(this.query, category)
  }

  // Handle focus on search input
  onFocus() {
    this.query = this.inputTarget.value
    const category = this.hasSelectTarget ? this.selectTarget.value : null
    
    // Skip search for Users and Companies categories
    if (category === "Users" || category === "Companies") {
      this.suggestionsTarget.style.display = "none"
      return
    }
    
    if (isEmpty(this.query)) return
    this.runQuery(this.query, category)
    this.suggestionsTarget.style.display = "flex"
  }

  // Handle blur on search input
  onBlur() {
    if (!this.clickedSuggestion) {
      this.suggestionsTarget.style.display = "none"
    }
  }

  // Handle suggestion click
  selectSuggestion(event) {
    const searchText = event.currentTarget.dataset.search
    const clientId = event.currentTarget.dataset.clientId
    this.clickedSuggestion = true
    this.suggestionsTarget.querySelectorAll('.suggest-result').forEach(el => el.remove())
    this.suggestionsTarget.style.display = "none"
    this.inputTarget.value = searchText

    if (this.modeValue === "clientSelect") {
      if (this.hasHiddenInputTarget) {
        this.hiddenInputTarget.value = clientId
      }
      // Dispatch a custom event that can be listened to
      const event = new CustomEvent('client:selected', { 
        detail: { clientId, clientName: searchText },
        bubbles: true 
      })
      this.element.dispatchEvent(event)
    } else {
      this.element.querySelector("#form-search-bar")?.submit()
    }
  }

  runQuery(query, category) {
    if (!query) {
      this.buildSuggestions(null)
      return
    }
    $.ajax({
      url: this.urlValue,
      type: "POST",
      dataType: 'json',
      data: { 
        "query": query,
        "category": category,
        "mode": this.modeValue
      },
      success: (data) => {
        this.buildSuggestions(data)
      }
    });
  }

  buildSuggestions(results) {
    this.suggestionsTarget.style.display = "flex"
    
    if (!results || (results[0].length <= 0 && results[1].length <= 0)) {
      this.noResults()
      return
    }

    this.clearExistingResults()

    // Client Results
    if (results[0]?.length) {
      this.suggestionHeaderTarget.style.display = "block"
      results[0].forEach(suggestion => {
        const displayName = suggestion._source.name_first && suggestion._source.name_last ? 
          `${suggestion._source.name_first} ${suggestion._source.name_last}` :
          suggestion._source.household_name || ''
          
        this.suggestionsTarget.insertAdjacentHTML('beforeend', `
          <div class='suggest-result' 
               data-action="mousedown->search#selectSuggestion" 
               data-search='${escape(displayName)}'
               data-client-id='${escape(suggestion._source.id)}'> 
            <div class='suggest-result-inner'> 
              <span class="text suggest-val">${escape(displayName)}</span> 
              <span class='suggest-type'><small class='color-gray-x-light'>client</small></span>
            </div>
          </div>
        `)
      })
    }

    // Only show account results in search mode
    if (this.modeValue === "search" && results[1]?.length) {
      this.suggestionHeaderTarget.style.display = "block"
      results[1].forEach(suggestion => {
        const source = suggestion._source
        this.suggestionsTarget.insertAdjacentHTML('beforeend', `
          <div class='suggest-result' data-action="mousedown->search#selectSuggestion" data-search='${escape(source.account_number)}'>
            <div class='suggest-result-inner'> 
              <span class="suggest-val">${escape(source.account_number)}</span>
              <span class="suggest-type"><small class="color-gray-x-light">account</small></span>
            </div>
            ${source.account_description ? `
              <small id="search-description" class="color-gray-x-light me-auto margin-remove-top">
                ${escape(source.account_description)}
              </small>
            ` : ''}
          </div>
        `)
      })
    }
  }

  noResults() {
    this.clearExistingResults()
    this.suggestionsTarget.insertAdjacentHTML('beforeend', '<span id="no-results">No Results Found...</span>')
    this.suggestionHeaderTarget.style.display = "none"
  }

  clearExistingResults() {
    this.suggestionsTarget.querySelector('#no-results')?.remove()
    this.suggestionsTarget.querySelectorAll('.suggest-result').forEach(el => el.remove())
    this.suggestionsTarget.querySelectorAll('.suggest-result-inner').forEach(el => el.remove())
  }
} 